import { UserRole } from '@/app/user/types';

export const BROWSER_STORAGE_VALUE_TRUE = 'TRUE';

export const REGEX = {
    DOMAIN_LOWERCASE: /^[a-z0-9.-]+$/,
    SUB_DOMAIN_LOWERCASE: /^[a-z0-9-]+$/,
};

export const enum CapabilitiesTarget {
    Campaign = 'campaign',
    Workspaces = 'workspaces',
    Contacts = 'contacts',
    // Analytics = 'analytics',
    // After adding a new capability target, make sure you update the roles accordingly (by default all permissions for the new capability target are set to false)
}

type RoleCapability = {
    [key in CapabilitiesTarget]: {
        canRead: boolean;
        canCreate: boolean;
        canUpdate: boolean;
        canDelete: boolean;
    };
};

const CRM_ROLE_CAPABILITIES: RoleCapability = {
    // full access to CRM related features
    [CapabilitiesTarget.Contacts]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // Can only read Workspaces
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
        canCreate: false,
        canUpdate: false,
        canDelete: false,
    },
    // Can only read Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
        canCreate: false,
        canUpdate: false,
        canDelete: false,
    },
};

const EDITOR_ROLE_CAPABILITIES: RoleCapability = {
    // No access to CRM related features
    [CapabilitiesTarget.Contacts]: {
        canRead: false,
        canCreate: false,
        canUpdate: false,
        canDelete: false,
    },
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
        canCreate: false,
        canUpdate: false,
        canDelete: false,
    },
    // All access to Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
};

const ADMIN_ROLE_CAPABILITIES: RoleCapability = {
    // All access to CRM related features
    [CapabilitiesTarget.Contacts]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Workspaces
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
};

export const RoleCapabilities = {
    [UserRole.crm]: CRM_ROLE_CAPABILITIES,
    [UserRole.editor]: EDITOR_ROLE_CAPABILITIES,
    [UserRole.admin]: ADMIN_ROLE_CAPABILITIES,
    [UserRole.schnitzel]: ADMIN_ROLE_CAPABILITIES,
};
