import { insertNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/insertNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { randomId } from '@/app/editor/engine/utils/randomId';
import { apiDelete } from '@/core/api';

import type { BlockResource } from '../../blocks/types';
import type { EditorEngineActionCreatorPayload } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The column will be created in this layout.
     */
    layoutId: string;
    /**
     * The position of the new column.
     */
    index: number;
}

/**
 * Action that will insert a column.
 */
export const insertColumnAction = (({
    args: { layoutId, index },
    documentManager,
    nodeManager,
    extraContext: { backendEntities, clientSideComponents },
}) => {
    const id = randomId();
    let newColumn: BlockResource;

    const props = {
        funnelId: documentManager.activeFunnel.id,
        pageId: documentManager.activePage.id,
        layoutId: nodeManager.resolveVirtualId(layoutId),
        index,
    } satisfies Parameters<typeof backendEntities.createColumn>[0];

    return {
        name: PerspectiveEditorEngineActionName.InsertColumn,
        debug: {},
        getTransaction() {
            newColumn = documentManager.makeColumn({
                id,
            });

            return [
                insertNodeStep({
                    node: {
                        block: newColumn,
                    },
                    parentId: layoutId,
                    index,
                }),
            ];
        },
        onAfterOptimisticForward() {
            if (newColumn) {
                clientSideComponents.scrollToComponent.focusOn(newColumn);
            }
        },
        onAfterOptimisticBackward() {
            documentManager.setActiveView('pages');
        },
        async execute() {
            const columnId = await backendEntities.createColumn(props);

            nodeManager.mapVirtualIdToConcreteId(id, columnId);

            return {
                success: true,
            };
        },
        async undo() {
            await apiDelete(`/components/${nodeManager.resolveVirtualId(id)}`);

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<Payload, {}>;
