import dynamic from 'next/dynamic';

import type { MediaType } from '@/app/mediaLibrary/types';
import type { FieldInputProps } from 'react-final-form';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps | FieldInputProps<string>;
    submit: () => void;
    mediaType?: MediaType;
}

const SidebarMediaLibrary = dynamic(
    () => import('@/app/mediaLibrary/components/SidebarMediaLibrary'),
);

export const MediaSrc = ({ input, submit, mediaType = 'image' }: Props) => {
    const { value, onChange } = input;

    const handleChange = (value: string) => {
        onChange(value);

        setTimeout(submit);
    };

    return <SidebarMediaLibrary currentSrc={value} onChange={handleChange} mediaType={mediaType} />;
};
