import formatDateIntl from 'date-fns/intlFormat';
import findIndex from 'lodash/findIndex';
import uniqueId from 'lodash/uniqueId';
import Router from 'next/router';
import { i18n } from 'next-i18next';
import qs from 'query-string';

import { getAnalyticsContactsUrl, getAnalyticsSessionsUrl } from '@/app/campaigns/helpers';
import { EMPTY_ARRAY } from '@/utils/empty';
import { LocalStorageValue } from 'types/generic';

import {
    NAME,
    ANALYTICS_KEYS,
    DEFAULT_PAGE_SIZE,
    DOT_FIELD_NAMES,
    DOT_FIELD_NAMES_MAP,
    SPLIT_TESTING_BANNER_KEY,
    MOCK_SERVER_ENABLED_KEY,
} from './constants';

import type {
    Answers,
    ChartPageAttributes,
    ChartPageWithSummary,
    Kpi,
    QuestionResponse,
    SchemaItem,
    Session,
    SplitTestPageAttributes,
    SplitTestResults,
} from './types';
import type { SubNavTabData } from '../navigation/types';

export const getSubNavTabs = (
    campaignId: string,
    filters: {
        showContacts: boolean;
        showSessions: boolean;
    },
): SubNavTabData[] => {
    return [
        {
            key: 'dashboard',
            path: `/funnel/${campaignId}/analytics/dashboard`,
        },
        {
            key: 'insights',
            path: `/funnel/${campaignId}/analytics/insights`,
        },
        filters?.showContacts && {
            key: 'contacts',
            path: `/funnel/${campaignId}/analytics/contacts`,
        },
        filters?.showSessions && {
            key: 'sessions',
            path: `/funnel/${campaignId}/analytics/sessions`,
        },
    ].filter(Boolean) as SubNavTabData[];
};

export const removeEmailFromSchema = (schema: SchemaItem[] = EMPTY_ARRAY): SchemaItem[] => {
    const index = findIndex(schema, { fieldName: 'email' });

    schema.splice(index, 1);

    return schema;
};

export const replaceDotFields = (schema: SchemaItem[] = EMPTY_ARRAY): SchemaItem[] => {
    schema.forEach((field, index) => {
        if (DOT_FIELD_NAMES.includes(field.fieldName)) {
            schema[index].fieldName = DOT_FIELD_NAMES_MAP[field.fieldName];
        }
    });

    return schema;
};

export const mapPageToChartSchema = ({ pageName, visitors }): ChartPageAttributes => {
    return {
        label: pageName,
        value: visitors,
    };
};

export const mapVariantPageToChartSchema = (
    { pageName, visitors },
    index: number,
): ChartPageAttributes => {
    const t = i18n?.getFixedT(null, NAME);

    return {
        label: `${pageName} (${index === 0 ? 'Original' : t('variant')})`,
        value: visitors,
    };
};

export const createAnalyticsUrl = ({
    campaignId,
    analyticsKey,
    startDate,
    endDate,
    variantPage,
}): string => {
    const startDateFormatted = new Date(startDate);
    const endDateFormatted = new Date(endDate);
    // TODO CF: get to know why this is here - not needed anymore?
    // startDateFormatted.setHours(12); // Default the date to 12pm
    // endDateFormatted.setHours(12); // Default the date to 12pm

    const params = qs.stringify({
        campaign: campaignId,
        start: startDate && startDateFormatted.toISOString(),
        end: endDate && endDateFormatted.toISOString(),
        page: variantPage && analyticsKey !== ANALYTICS_KEYS.variants ? variantPage : undefined,
    });

    return `/analytics/${analyticsKey}?${params}`;
};

export const createFetchSessionsUrl = ({
    campaignId,
    page,
    search,
    orderField,
    orderValue,
    status,
}): string => {
    const params = qs.stringify({
        campaign: campaignId,
        page,
        limit: DEFAULT_PAGE_SIZE,
        search,
        orderField,
        orderValue,
        status,
    });

    return `/sessions?${params}`;
};

export const createKpis = (
    kpis: SplitTestPageAttributes['report'],
    onSessionsClick: () => void,
    onLeadsClick: () => void,
): Kpi[] => {
    return [
        {
            identifier: 'visitors',
            value: kpis.sessions,
            onClick: onSessionsClick,
        },
        {
            identifier: 'leads',
            value: kpis.leads,
            onClick: onLeadsClick,
        },
        {
            identifier: 'conversionRate',
            value: kpis.conversion,
        },
        {
            identifier: 'completionRate',
            value: kpis.completion,
        },
    ];
};

const createInitialsFromEmail = (email: string): string => {
    if (!email) {
        return undefined;
    }

    return email.substring(0, 2).toUpperCase();
};

export const enrichSessions = (sessions: Session[]): Session[] => {
    if (!sessions) {
        return EMPTY_ARRAY;
    }

    return sessions.map((session) => {
        const profile = session?.attributes?.profile;
        const answers: Answers = {} as Answers;

        profile.forEach((field) => {
            if (DOT_FIELD_NAMES.includes(field?.fieldName)) {
                answers[DOT_FIELD_NAMES_MAP[field?.fieldName]] = field?.value
                    ? field?.value?.toString()
                    : '-';
            } else {
                answers[field?.fieldName] = field?.value ? field?.value?.toString() : '-';
            }
        });

        const initials = createInitialsFromEmail(answers?.email);

        session.answers = answers;
        session.avatarInitials = initials;

        return session;
    });
};

export const removeSession = (sessions: Session[], sessionId: string): Session[] => {
    const index = findIndex(sessions, { id: sessionId });

    if (index > -1) {
        sessions.splice(index, 1);
    }

    return sessions;
};

export const createFakeTestResults = (
    originalPageId: string,
    variantPageId: string,
): SplitTestResults => {
    return {
        pages: [
            {
                id: originalPageId,
                isWinner: false,
                report: {
                    sessions: '- ',
                    leads: '- ',
                    conversion: '- ',
                },
            },
            {
                id: variantPageId,
                isWinner: false,
                report: {
                    sessions: '- ',
                    leads: '- ',
                    conversion: '- ',
                },
            },
        ],
    };
};

export const generateKeys = (array: any[], prefix: string) => {
    if (!array) {
        return EMPTY_ARRAY;
    }

    return array.map(() => uniqueId(prefix));
};

export const sortResponses = (responses: QuestionResponse[]): QuestionResponse[] =>
    responses.sort((prev, next) => {
        return next.count - prev.count;
    });

export const groupResponses = (responses: QuestionResponse[]): QuestionResponse[] => {
    const groupedResponses: QuestionResponse[] = [];
    let recordsCount = 0;

    responses.forEach((response) => {
        const splitValues = response.value?.split('; ');

        splitValues.forEach((value) => {
            const record = groupedResponses.find((res) => res.value === value);
            recordsCount += response.count;

            if (!record) {
                groupedResponses.push({
                    value,
                    count: response.count,
                    visual: null,
                    visualType: null,
                });
            } else {
                record.count += response.count;
            }
        });
    });

    groupedResponses.forEach((response) => {
        response.percentage = (100 / recordsCount) * response.count;
    });

    return sortResponses(groupedResponses);
};

export const navigateToSessions = (campaignId: string) => async () => {
    await Router.push(getAnalyticsSessionsUrl(campaignId));
};

export const navigateToContacts = (campaignId: string) => async () => {
    await Router.push(getAnalyticsContactsUrl(campaignId));
};

export const getHasSeenSplitTestingBanner = (campaignId: string) => {
    return localStorage.getItem(SPLIT_TESTING_BANNER_KEY + campaignId);
};

export const setHasSeenSplitTestingBanner = (campaignId: string) => {
    localStorage.setItem(SPLIT_TESTING_BANNER_KEY + campaignId, LocalStorageValue.TRUE);
};

export const getIsMockServerEnabled = () => {
    return localStorage.getItem(MOCK_SERVER_ENABLED_KEY) === LocalStorageValue.TRUE;
};

export const setIsMockServerEnabled = (enabled: boolean) => {
    localStorage.setItem(
        MOCK_SERVER_ENABLED_KEY,
        enabled ? LocalStorageValue.TRUE : LocalStorageValue.FALSE,
    );
};

export const getLoadingPages = (
    originalName: string,
    variantName: string,
): SplitTestPageAttributes[] => [
    {
        id: '123',
        name: originalName,
        isWinner: false,
        report: {
            sessions: '...',
            leads: '...',
            conversion: '...',
        },
    },
    {
        id: '456',
        name: variantName,
        isWinner: false,
        report: {
            sessions: '...',
            leads: '...',
            conversion: '...',
        },
    },
];

export const getIsChartEmpty = (pages: ChartPageWithSummary[]): boolean =>
    pages?.length && !pages.some((page) => page?.value > 0);

export const getFormattedBirthday = (value: string, locale: string) => {
    const birthDate = new Date(value);

    if (!isNaN(birthDate.valueOf())) {
        return formatDateIntl(
            birthDate,
            { day: 'numeric', month: 'long', year: 'numeric' },
            { locale },
        );
    }

    return value;
};

export const getFormattedDate = (value: string, locale: string) => {
    const birthDate = new Date(value);

    if (!isNaN(birthDate.valueOf())) {
        return formatDateIntl(
            birthDate,
            { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' },
            { locale },
        );
    }

    return value;
};
