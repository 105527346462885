import {
    ENDPOINTS,
    FIXED_FOLDER_IDS,
    MUTATION_KEYS,
    QUERY_KEYS,
    UPLOAD_RESET_TIMEOUT_MS,
} from '@/app/mediaLibrary/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useUseImage } from '@/app/mediaLibrary/hooks/mutations/useUseImage';
import { apiPost } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { reportMessage } from '@/utils/sentry';

import type { ImageResource } from '@/app/mediaLibrary/types';
import type { ResponseData } from '@/core/api/types';

// Helper
const getRequestPayload = ({
    file,
    workspaceId,
    folderId,
}: {
    file: File;
    workspaceId: string;
    folderId: string;
}) => {
    const payload = new FormData();

    payload.append('file', file);
    payload.append('workspace', workspaceId);

    if (folderId !== FIXED_FOLDER_IDS.all) {
        payload.append('folder', folderId);
    }

    return payload;
};

export const useUploadImages = () => {
    const queryClient = useQueryClient();
    const { mutateAsync: setUseImage } = useUseImage();

    const [progress, setProgress] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const reset = () => {
        setProgress(0);
        setUploadedCount(0);
        setTotalCount(0);
    };

    const mutation = useMutation({
        mutationKey: MUTATION_KEYS.uploadImages(),
        mutationFn: async ({
            folderId,
            files,
            workspaceId,
        }: {
            folderId: string;
            files: File[];
            workspaceId: string;
            successCb: (uploadedImages: ImageResource[]) => void;
        }) => {
            setTotalCount(files.length);
            const uploadedImages: ImageResource[] = [];

            for (const [index, file] of files.entries()) {
                const payload = getRequestPayload({ file, workspaceId, folderId });

                const response = await apiPost<ResponseData<ImageResource>>(
                    ENDPOINTS.POST.uploadImages(),
                    payload,
                    {
                        headers: { 'Content-Type': undefined },
                    },
                );

                // Required to move the uploaded image to the top of the list in the folder
                // TODO: ask a BE guy to add the uploaded images to the start of the list right away?
                await setUseImage({ imageId: getDataFromResponse(response).id });

                setUploadedCount(index + 1);
                setProgress((index + 1) / files.length);

                const image = getDataFromResponse(response);

                uploadedImages.push(image);
            }

            return uploadedImages;
        },
        onSuccess: async (data, variables) => {
            const { folderId, successCb } = variables;

            if (successCb) {
                successCb(data);
            }

            await queryClient.invalidateQueries({
                queryKey: QUERY_KEYS.folderImages(folderId),
            });

            // Also invalidate "All images"
            if (folderId !== FIXED_FOLDER_IDS.all) {
                await queryClient.invalidateQueries({
                    queryKey: QUERY_KEYS.folderImages(FIXED_FOLDER_IDS.all),
                });
            }

            setTimeout(() => {
                reset();
            }, UPLOAD_RESET_TIMEOUT_MS);
        },
        onError: (err) => {
            reset();
            reportMessage({
                message: `Failed to upload images: ${err.message}`,
                source: 'image-library',
                severityLevel: 'warning',
            });
        },
    });

    return { ...mutation, progress, totalCount, uploadedCount };
};
