import { NAME, PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS } from '@/app/crm/constants';
import { ORIGIN_MAP } from '@/ui/constants';

import { FloatingPortal } from '@floating-ui/react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { AnimatePresence, motion } from 'framer-motion';
import isArray from 'lodash/isArray';
import { useTranslation } from 'next-i18next';

import { useFloatingDropdown } from '@/app/crm/hooks/useFloatingDropdown';
import { cn } from '@/utils/cn';

import { AnswerPill } from './AnswerPill';
import { AnswerPills } from './AnswerPills';

import type { SelectOption, PropertyFieldProps } from '@/app/crm/types';
import type { MouseEvent } from 'react';

export interface Props extends PropertyFieldProps {
    options: SelectOption[];
    selected: string[] | string;
    open: boolean;
    isMultiSelect?: boolean;
    handleDeselectOption?: (index: number) => (event: MouseEvent) => void;
}

export const ListBoxDropdown = ({
    options,
    selected,
    open,
    isMultiSelect,
    handleDeselectOption,
    inline,
}: Props) => {
    const { t } = useTranslation(NAME);

    const { x, y, strategy, refs, placement } = useFloatingDropdown(open);

    return (
        <>
            <Listbox.Button
                className={cn(
                    'group flex w-full rounded-lg p-2 text-sm text-gray-700 ring-1 ring-transparent transition-colors hover:bg-gray-100 hover:text-gray-900',
                    {
                        'bg-gray-100 ring-blue-500': open,
                        'text-gray-600 hover:bg-gray-200 hover:text-gray-900': inline,
                    },
                )}
                ref={refs.setReference}
            >
                {selected.length > 0 ? (
                    isMultiSelect && isArray(selected) ? (
                        <AnswerPills
                            inline={inline}
                            selected={selected}
                            handleDeselectOption={handleDeselectOption}
                        />
                    ) : (
                        <AnswerPill inline={inline}>{selected as string}</AnswerPill>
                    )
                ) : (
                    <span className="text-gray-400">{t('select-an-option')}</span>
                )}
            </Listbox.Button>

            <AnimatePresence>
                {open && (
                    <FloatingPortal>
                        <motion.div
                            className={cn('z-50 w-max', ORIGIN_MAP[placement])}
                            // Floating UI props
                            ref={refs.setFloating}
                            style={{
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0,
                            }}
                            // Framer motion props
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                                transition: { type: 'spring', duration: 0.2, bounce: 0 },
                            }}
                            exit={{
                                opacity: 0,
                                scale: 0.9,
                                transition: { type: 'spring', duration: 0.15, bounce: 0 },
                            }}
                        >
                            <Listbox.Options
                                className={cn(
                                    'max-h-80 w-full overflow-auto rounded-lg border bg-white p-2 text-sm shadow-dropdown',
                                    PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                                )}
                            >
                                {options.map((option, index) => {
                                    const isSelected = selected.includes(option.value);

                                    return (
                                        <Listbox.Option
                                            key={`${option.value}-${index}`}
                                            value={option.value}
                                            className={cn(
                                                'flex cursor-pointer items-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-800',
                                                PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                                                {
                                                    'text-gray-800': isSelected,
                                                },
                                            )}
                                        >
                                            <CheckIcon
                                                className={cn('mr-2 size-4 text-blue-500', {
                                                    invisible: !isSelected,
                                                })}
                                            />
                                            <span>{option.value}</span>
                                        </Listbox.Option>
                                    );
                                })}
                            </Listbox.Options>
                        </motion.div>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};
