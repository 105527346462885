import { NAME } from '@/app/editor/editor/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { PhotoIcon } from '@heroicons/react/24/outline';
import { ImageFocalPoint } from '@lemoncode/react-image-focal-point';
import debounce from 'lodash/debounce';
import { useTranslation } from 'next-i18next';
import { useCallback, useRef } from 'react';
import { change } from 'redux-form';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';

import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface Props {
    src: string;
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    submit: () => void;
}

export const BgImageFocalPoint = ({ src, input, meta, submit }) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { value, onChange } = input;

    const debouncedSubmit = useRef(
        debounce(() => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.focalPoint, {});
            submit();
        }, 1000),
    );

    const handleFocalPointChange = useCallback(
        (focalPoint: { x: number; y: number }) => {
            onChange(focalPoint);
            setTimeout(debouncedSubmit.current);
        },
        [onChange, debouncedSubmit],
    );

    const handleEmptyClick = useCallback(() => {
        track(TRACKING_EVENTS.campaign.editor.bgImage.select, {});

        dispatch(
            showModal(
                Modals.MEDIA_LIBRARY,
                {
                    currentSrc: src,
                    onChange: (src: string) => {
                        dispatch(change(meta.form, 'attributes.content.box.bgImage.src', src));
                        setTimeout(submit);
                    },
                },
                MODAL_OPTIONS[Modals.MEDIA_LIBRARY],
            ),
        );
    }, [dispatch, src, meta.form, submit]);

    return src ? (
        <div className="relative flex min-h-[200px] items-center justify-center overflow-hidden rounded-lg bg-gray-100">
            <ImageFocalPoint
                src={src}
                alt={t('focal-point.selection-alt')}
                focalPoint={value}
                className="max-h-full max-w-full object-contain"
                onChange={handleFocalPointChange}
            />
        </div>
    ) : (
        <div className="relative h-[200px] rounded-lg bg-gray-100 p-2">
            <button
                className="bump flex size-full flex-col items-center justify-center gap-2 rounded-md border-2 border-dashed border-gray-300 text-sm text-gray-400 outline-none transition-colors hover:text-gray-500"
                onClick={handleEmptyClick}
            >
                <PhotoIcon className="size-8" />
                {t('focal-point.select-bg-image')}
            </button>
        </div>
    );
};
