import { MODAL_OPTIONS } from '@/app/modals/constants';

import {
    closestCenter,
    DndContext,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

import { getActiveIndexBySliderId, setActiveIndex } from '@/app/editor/blocks/models/slider';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { SortableImage } from './SortableImage';

import type { Testimonial } from '@/app/editor/blocks/types';
import type { FixedFolderId } from '@/app/mediaLibrary/types';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import type { WrappedFieldInputProps } from 'redux-form';

interface Props {
    blockId: string;
    input: WrappedFieldInputProps;
    submit: () => void;
    serializeItem: (item: string | Testimonial, index: number) => string;
    deserializeItem: <T = string>(item: string | Testimonial) => T;
    getImageUrl: (item: string | Testimonial) => string;
    createNewItem: <T>(item: string | Testimonial) => T;
    containImage?: boolean;
    initialFolderId?: FixedFolderId;
    maxCount?: number;
}

const hashSerialize = (item: string, index: number) => `${index}___${item}`;
const hashDeserialize = <T,>(item: string) => item.split(/___(.*)/s)[1] as T; // split only on first occurrence of ___
const hashImageGetter = (item: string) => item;
const createImage = <T,>(item: string) => item as T;

const Slides = ({
    blockId,
    input,
    submit,
    containImage,
    serializeItem = hashSerialize,
    deserializeItem = hashDeserialize,
    getImageUrl = hashImageGetter,
    createNewItem = createImage,
    initialFolderId,
    maxCount = 16,
}: Props) => {
    const dispatch = useAppDispatch();
    const serializedItems = input?.value ? input.value.map(serializeItem) : [];

    const [activeId, setActiveId] = useState<string>(null);
    const [items, setItems] = useState<string[]>(serializedItems);
    const [newImage, setNewImage] = useState(undefined);
    const activeIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));

    useEffect(() => {
        if (activeIndex >= items.length) {
            dispatch(setActiveIndex({ sliderId: blockId, index: items.length - 1 }));
        }
    }, [activeIndex, blockId, dispatch, items.length]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
    );

    useEffect(() => {
        if (input?.value) {
            setItems(input?.value?.map(serializeItem));
        }
    }, [input.value, serializeItem]);

    useEffect(() => {
        if (!activeIndex) {
            dispatch(setActiveIndex({ sliderId: blockId, index: 0 }));
        }
    }, [activeIndex, blockId, dispatch]);

    const updateAndSubmit = (items: string[]) => {
        // Extract image names for API
        const newImageArray = items.filter(Boolean).map(deserializeItem);

        // Update image names with indexes
        const updatedItemsArray = newImageArray.map(serializeItem);

        // Update slider state
        setItems(updatedItemsArray);

        // Update and submit form
        input.onChange(newImageArray);
        setTimeout(submit);
    };

    const openImageLibrary = () => {
        dispatch(
            showModal(
                Modals.MEDIA_LIBRARY,
                {
                    onChange: () => {
                        setNewImage(undefined);
                    },
                    onNextClick: (currentSrc: string) => {
                        const newItems = [
                            ...items,
                            serializeItem(createNewItem(currentSrc), items.length),
                        ];

                        setNewImage(undefined);
                        updateAndSubmit(newItems);
                        dispatch(setActiveIndex({ sliderId: blockId, index: newItems.length - 1 }));
                    },
                    initialFolderId,
                },
                {
                    ...MODAL_OPTIONS[Modals.MEDIA_LIBRARY],
                },
            ),
        );
    };

    const handleDelete = (indexToRemove: number) => {
        const newItems = items.filter((item: string) => !item.startsWith(indexToRemove.toString()));

        dispatch(setActiveIndex({ sliderId: blockId, index: newItems.length - 1 }));

        updateAndSubmit(newItems);
    };

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id as string);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active?.id !== over?.id && over?.id) {
            const oldIndex = items.indexOf(active.id as string);
            const newIndex = items.indexOf(over.id as string);

            // Deal with sorting
            const newItemsArray = arrayMove<string>(items, oldIndex, newIndex);
            dispatch(setActiveIndex({ sliderId: blockId, index: newIndex }));

            updateAndSubmit(newItemsArray);
        }

        setActiveId(null);
    };

    return (
        <div className="mt-4 grid grid-cols-2 gap-4">
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                    {items.map((image, index) => (
                        <SortableImage
                            key={getImageUrl(deserializeItem(image))}
                            blockId={blockId}
                            id={image}
                            imageUrl={getImageUrl(deserializeItem(image))}
                            number={index + 1}
                            onDelete={items.length > 1 ? handleDelete : undefined}
                            containImage={containImage}
                        />
                    ))}
                    {newImage && (
                        <SortableImage
                            key={newImage}
                            blockId={blockId}
                            id={newImage}
                            imageUrl={newImage}
                            number={items.length + 1}
                            containImage={containImage}
                        />
                    )}
                </SortableContext>
                <DragOverlay>
                    {activeId ? (
                        <SortableImage
                            blockId={blockId}
                            id={activeId}
                            number={items.indexOf(activeId) + 1}
                            imageUrl={getImageUrl(deserializeItem(activeId))}
                            onDelete={handleDelete}
                            containImage={containImage}
                        />
                    ) : null}
                </DragOverlay>
            </DndContext>
            {!newImage && items.length < maxCount && (
                <button
                    className="flex items-center justify-center rounded-md bg-gray-100 text-gray-400 aspect-4/3 hover:text-gray-600"
                    onClick={openImageLibrary}
                >
                    <PlusIcon className="size-8" />
                </button>
            )}
        </div>
    );
};

export default Slides;
