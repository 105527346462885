import isEqual from 'lodash/isEqual';

import { changeNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/changeNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';

import type { BlockResource, EditFormValues } from '@/app/editor/blocks/types';
import type { EditorEngineActionCreatorPayload } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The values to update the block with.
     */
    values: EditFormValues;
}

/**
 * Action that will update the properties of a block.
 */
export const changeBlockAction = (({
    args: { values },
    nodeManager,
    extraContext: { backendEntities, clientSideComponents },
}) => {
    const { additionalBlocks, ...rootBlock } = values;
    let oldBlocks: BlockResource[] = [];
    const blocksToUpdate = [rootBlock];

    if (additionalBlocks) {
        const blockIds = Object.keys(additionalBlocks);

        blockIds.forEach((blockId) => {
            const block = nodeManager.getNode(blockId).block;
            const updatedBlock = {
                ...block,
                attributes: {
                    ...block.attributes,
                    content: {
                        ...block.attributes.content,
                        ...additionalBlocks[blockId].attributes.content,
                    },
                },
            };

            blocksToUpdate.push(updatedBlock);
        });
    }

    return {
        name: PerspectiveEditorEngineActionName.ChangeBlock,
        debug: {},
        shouldBeSkipped() {
            for (const blockToUpdate of blocksToUpdate) {
                const oldBlock = nodeManager.getNode(blockToUpdate.id).block;

                if (!isEqual(oldBlock, blockToUpdate)) {
                    return false;
                }
            }

            // Skip if all blocks are the same as before
            return true;
        },
        getTransaction() {
            return blocksToUpdate.map((blockToUpdate) =>
                changeNodeStep({
                    id: blockToUpdate.id,
                    newDataGetter: () => ({ block: blockToUpdate }),
                }),
            );
        },
        onAfterOptimisticForward(isRedo) {
            if (isRedo) {
                clientSideComponents.scrollToComponent.focusOn(rootBlock);
            }
        },
        onAfterOptimisticBackward() {
            clientSideComponents.scrollToComponent.focusOn(rootBlock);
        },
        onBeforeExecute() {
            oldBlocks = blocksToUpdate.map(
                (blockToUpdate) => nodeManager.getNode(blockToUpdate.id)?.block,
            );
        },
        async execute() {
            await backendEntities.updateBlock({
                virtualBlocks: blocksToUpdate,
                resolveVirtualId: nodeManager.resolveVirtualId,
                updateBlockAttributes: nodeManager.updateBlockAttributes,
                mapVirtualIdToConcreteId: nodeManager.mapVirtualIdToConcreteId,
            });

            return {
                success: true,
            };
        },
        async undo() {
            await backendEntities.updateBlock({
                virtualBlocks: oldBlocks.filter(Boolean),
                resolveVirtualId: nodeManager.resolveVirtualId,
                updateBlockAttributes: nodeManager.updateBlockAttributes,
                mapVirtualIdToConcreteId: nodeManager.mapVirtualIdToConcreteId,
            });

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<Payload, {}, {}>;
