import Image from 'next/image';
import qs from 'query-string';
import { useEffect, useMemo, useRef, useState } from 'react';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import { getMediaBorderRadius, getMaxWidth } from '@/app/editor/blocks/components/Media/helper';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';
import LoadingIndicator from '@/ui/components/LoadingIndicator';
import { cn } from '@/utils/cn';

import type { BoxProps, Size } from '../../../types';
import type { BorderRadius, ThemeColorKey } from '@/app/editor/themes/types';
import type { SyntheticEvent } from 'react';

export interface Props {
    src: string;
    size: Size;
    box: BoxProps;
    artBoardIndex: number;
    hasActiveParent: boolean;
    hasParentWithActiveChild: boolean;
    artBoardSize: ArtBoardSize;
    isInMediaAnswer?: boolean;
    isInFramedColumn?: boolean;
    isInListItem?: boolean;
    framed?: boolean;
    isDragged?: boolean;
    borderRadius?: BorderRadius;
    themedBackgroundColor?: ThemeColorKey;
    isActive?: boolean;
    isInColumn?: boolean;
    hideBg?: boolean;
    onUpdateForm?: (imgixUrl: string) => Promise<void>;
}

export const PictureItem = ({
    src,
    size,
    framed,
    borderRadius,
    isDragged,
    box,
    artBoardIndex,
    themedBackgroundColor,
    isActive,
    isInColumn,
    hasActiveParent,
    hasParentWithActiveChild,
    artBoardSize,
    onUpdateForm,
    isInMediaAnswer = false,
    isInFramedColumn = false,
    isInListItem = false,
    hideBg = false,
}: Props) => {
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [originalAspectRatio, setOriginalAspectRatio] = useState<number>();

    useEffect(() => {
        setMounted(true);
    }, []);

    const bgColor =
        isInColumn || isInListItem || hideBg
            ? 'transparent'
            : getThemedOrCustomColor(
                  box.backgroundColor,
                  themedBackgroundColor ? themedBackgroundColor : 'backgroundColor',
                  previewOrActiveTheme,
              );

    const isCustomSize = useMemo(() => size === 'custom', [size]);

    const imgixUrl = useMemo(() => {
        const params = qs.parseUrl(src).query;
        const cleanSrc = src.split('?')[0];

        // if image is cropped, don't try to auto-crop it
        if (params.crop === 'focalpoint') {
            return src;
        }

        // else auto crop to faces,entropy
        // unless it's a gif. In that case, use "center" crop
        const newParams = {
            crop: /\.gif/.test(cleanSrc) ? ['center'] : ['faces', 'entropy'],
            fit: 'crop',
            auto: ['compress', 'format'],
            dpr: 2,
            w: getMaxWidth(artBoardSize),
        };
        const query = qs.stringify(newParams, { arrayFormat: 'comma' });

        return `${cleanSrc}?${query}`;
    }, [src, artBoardSize]);

    const imgixUrlCacheRef = useRef(imgixUrl);

    const isStandaloneImage = !isInListItem && !isInMediaAnswer;
    const isFullWidth =
        isStandaloneImage &&
        artBoardSize === ArtBoardSize.MOBILE &&
        !isInFramedColumn &&
        (isInColumn || !framed);

    const hasFakePadding =
        isFullWidth &&
        isInColumn &&
        !isDragged &&
        !isActive &&
        !hasActiveParent &&
        !hasParentWithActiveChild;

    // Update form/block with new URL
    useEffect(() => {
        // Update only if URL changed
        if (imgixUrlCacheRef.current !== imgixUrl) {
            setLoading(true);
            imgixUrlCacheRef.current = imgixUrl;

            onUpdateForm?.(imgixUrl);
        }
    }, [imgixUrl, onUpdateForm]);

    const roundingClass = getMediaBorderRadius({
        borderRadius: previewOrActiveTheme.attributes.borderRadius,
        isInListItem,
        isInMediaAnswer,
        isFullWidth,
        forceLarge: borderRadius === 'large',
    });

    const handleImgLoaded = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
        const img = evt.currentTarget;

        setOriginalAspectRatio(img.naturalWidth / img.naturalHeight);
        setLoading(false);
    };

    const handleLoadError = () => {
        setLoading(false);
    };

    return (
        <Box
            box={{ ...box, backgroundColor: bgColor }}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <div
                className={cn('relative transition-all', {
                    'px-14 py-6': artBoardSize === ArtBoardSize.TABLET && framed && !isInColumn,
                    'px-16 py-8': artBoardSize === ArtBoardSize.DESKTOP && framed && !isInColumn,
                })}
            >
                <div
                    className={cn('relative transition-all duration-[400] ease-out', {
                        'w-full': !hasFakePadding,
                        'w-[calc(100%+48px)] -translate-x-6': hasFakePadding,
                        'aspect-16/9': size === 'small',
                        'aspect-4/3': size === 'medium' || size === 'answer',
                        'aspect-5/4': size === 'large',
                        'aspect-square': size === 'xLarge' || isInListItem,
                        'overflow-hidden': isCustomSize,
                    })}
                    style={isCustomSize ? { aspectRatio: originalAspectRatio } : {}}
                >
                    <Image
                        src={imgixUrl}
                        alt="Chosen media"
                        className={cn(
                            'relative h-auto w-full object-cover transition-all',
                            roundingClass,
                            {
                                'aspect-16/9': size === 'small',
                                'aspect-4/3': size === 'medium',
                                'aspect-5/4': size === 'large',
                                'aspect-square': size === 'xLarge',
                            },
                        )}
                        onLoad={handleImgLoaded}
                        onError={handleLoadError}
                        fill
                    />
                </div>
                {!isDragged && mounted && (
                    <LoadingIndicator
                        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                        visible={loading}
                    />
                )}
            </div>
        </Box>
    );
};
