import { Modals } from './types';

export const NAME = 'modals';

export const TRANSITION_DURATION = 200; // in/out modal transition duration. make sure to adjust as well in components/BaseModal/modal.css

export const MODAL_OPTIONS = {
    [Modals.TEMPLATE_DETAILS]: {
        className: 'max-w-screen-lg mx-auto',
    },
    [Modals.MEDIA_LIBRARY]: {
        className: 'max-w-screen-lg mx-auto h-4/5',
    },
    [Modals.FUNNEL_SETTINGS]: {
        className: 'max-w-screen-lg mx-auto h-4/5',
    },
    [Modals.WORKSPACE_SETTINGS]: {
        className: 'max-w-screen-lg mx-auto px-4 h-5/6',
    },
    [Modals.IMAGE_CROPPING]: {
        className: 'max-w-[752px] mx-auto px-4',
        flexClassName: '!items-start pt-16',
        shouldCloseOnOverlayClick: false,
    },
    [Modals.INTEGRATION_PROFILE]: {
        className: 'max-w-[920px] mx-auto',
        flexClassName: 'items-baseline',
    },
    [Modals.STATUS_TEMPLATES]: {
        className: 'max-w-[669px] mx-auto h-full !pt-[7%]',
    },
    [Modals.ADD_CONTACT]: {
        className: 'max-w-[669px] mx-auto h-full !pt-[7%]',
    },
    [Modals.EXPORT_CRM]: {
        className: 'max-w-[669px] mx-auto h-full !pt-[7%]',
    },
    [Modals.SHARE_CONTACTS_MODAL]: {
        className: 'max-w-[400px] mx-auto sm:max-w-[432px]',
    },
    [Modals.CONFIRM_FILE_DELETION]: {
        className: 'max-w-[400px] mx-auto sm:max-w-[432px]',
        flexClassName: 'prevent-sidebar-click-outside',
    },
    [Modals.REFERRAL_WATCH_VIDEO]: {
        className: 'max-w-full mx-auto max-w-3xl',
    },
    [Modals.INVITE_MEMBER]: {
        className: 'max-w-screen-sm mx-auto',
    },
    [Modals.ADD_WIDGET]: {
        className: 'max-w-screen-xl mx-auto h-[calc(100vh-7%)] self-end relative sm:py-0',
    },
    tall: {
        className: 'mx-auto max-w-screen-sm',
        flexClassName: '!items-start pt-16',
    },
    full: {
        className: 'h-full max-w-full p-0 sm:p-0',
        flexClassName: 'items-baseline',
    },
};
