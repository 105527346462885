import {
    IMAGES_PER_PAGE,
    MAX_UNSPLASH_RECENT_SEARCH,
    RECENT_UNSPLASH_SEARCHES,
} from '@/app/mediaLibrary/constants';

import qs from 'query-string';

import { unsplashGet } from '@/core/api/unsplash';
import { EMPTY_ARRAY } from '@/utils/empty';
import { reportError } from '@/utils/sentry';

import type { ImageData, UnsplashImage } from '@/app/mediaLibrary/types';
import type { Pagination } from '@/core/api/types';

// Fetch Unsplash images
export const dataFetchUnsplash = async (
    url: string,
    isSearch?: boolean,
): Promise<{ images: ImageData[]; pagination: Pagination }> => {
    const response = await unsplashGet(url);

    const query = url.split('?')[1];
    const queryParams = qs.parse(query);
    const currentPage: number = parseInt(queryParams.page as string) || 1;
    const totalPages = response?.data?.total_pages;

    const paginationQueryParams = {
        ...queryParams,
        page: currentPage + 1,
    };

    const pagination: Pagination = {
        count: IMAGES_PER_PAGE * (currentPage + 1),
        totalCount: response?.data?.total,
        pageCount: totalPages,
    };

    pagination.next = isSearch
        ? currentPage < totalPages
            ? `/search/photos?${qs.stringify(paginationQueryParams)}`
            : undefined
        : `/photos?${qs.stringify(paginationQueryParams)}`;

    const imageData: UnsplashImage[] = isSearch ? response?.data?.results : response?.data;

    const images = imageData?.map((image) => ({
        id: image?.id,
        url: image?.urls?.regular,
        thumb: image?.urls?.small,
        author: image?.user?.name,
        description: image?.description,
    }));

    return { images, pagination };
};

const getRecentSearchStorageKey = (campaignId: string) => {
    return `${RECENT_UNSPLASH_SEARCHES}--${campaignId}`;
};

export const getSavedUnsplashSearchTerm = (campaignId: string): string[] => {
    const storageKey = getRecentSearchStorageKey(campaignId);

    try {
        const item = localStorage.getItem(storageKey);

        if (item) {
            return JSON.parse(localStorage.getItem(storageKey));
        }

        return EMPTY_ARRAY;
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return EMPTY_ARRAY;
    }
};

export const saveUnsplashSearchTerm = (campaignId: string, term: string) => {
    const storageKey = getRecentSearchStorageKey(campaignId);

    try {
        const savedTerms = getSavedUnsplashSearchTerm(campaignId);
        const termTrimmed = term?.trim();

        if (termTrimmed && !savedTerms.includes(termTrimmed)) {
            const items = [termTrimmed, ...savedTerms].slice(0, MAX_UNSPLASH_RECENT_SEARCH);

            localStorage.setItem(storageKey, JSON.stringify(items));

            return items;
        }

        return savedTerms;
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return EMPTY_ARRAY;
    }
};
