import { FIXED_FOLDER_IDS } from '@/app/mediaLibrary/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useCallback } from 'react';
import { change } from 'redux-form';

import { getCampaignWorkspaceId } from '@/app/campaigns/helpers';
import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { CropButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import { useUploadImages } from '@/app/mediaLibrary/hooks/mutations/useUploadImages';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';

import { FileUploadButton } from './FileUploadButton';
import { ImageLibraryButton } from './ImageLibraryButton';

import '@lemoncode/react-image-focal-point/style.css';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    blockId: string;
    input: WrappedFieldInputProps;
    submit: () => void;
}

export const BgImageSrc = ({ blockId, input, submit }: Props) => {
    const { value, onChange } = input;
    const dispatch = useAppDispatch();
    const campaign = useAppSelector(getActiveCampaign);
    const workspaceId = getCampaignWorkspaceId(campaign);

    const { mutate: uploadImages, isPending } = useUploadImages();

    const handleFileUpload = useCallback(
        (file: File) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.upload, {});

            uploadImages({
                folderId: FIXED_FOLDER_IDS.all,
                files: [file],
                workspaceId,
                successCb: (uploadedImages) => {
                    onChange(uploadedImages[0].attributes.url);
                    setTimeout(submit);
                },
            });
        },
        [onChange, submit, uploadImages, workspaceId],
    );

    const handleCropSave = useCallback(
        (src: string) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.crop, {});

            dispatch(change(blockId, 'attributes.content.box.bgImage.src', src));
            setTimeout(submit);
        },
        [dispatch, blockId, submit],
    );

    const handleImageSelect = useCallback(
        (src: string) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.select, {});

            onChange(src);
            setTimeout(submit);
        },
        [onChange, submit],
    );

    return (
        <div className="mt-2 grid grid-cols-3 gap-2">
            <FileUploadButton onChange={handleFileUpload} loading={isPending} />
            <ImageLibraryButton currentSrc={value} onChange={handleImageSelect} />
            <CropButton src={value} onSave={handleCropSave} size="custom" />
        </div>
    );
};
