import { CapabilitiesTarget, RoleCapabilities } from '@/utils/constants';

import { getUser } from '@/app/user/models/user';
import { UserRole } from '@/app/user/types';
import { getIsPreview } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';

export const useUserCapabilities = (
    target: CapabilitiesTarget,
): { canCreate: boolean; canUpdate: boolean; canRead: boolean; canDelete: boolean } => {
    const user = useAppSelector(getUser);
    const isPreview = useAppSelector(getIsPreview);
    const role = user?.attributes?.role || '';

    // if the user doesn't have a role or the role doesn't contain the capabilities target, return false
    if (!role || !RoleCapabilities[role as string][target]) {
        return {
            canCreate: false,
            canUpdate: false,
            canRead: false,
            canDelete: false,
        };
    }

    // if it's a preview, return the CRM capabilities
    if (isPreview) {
        return RoleCapabilities[UserRole.crm][target];
    }

    return RoleCapabilities[role as string][target];
};

// Re-export CapabilitiesTarget to avoid updating 40+ files
export { CapabilitiesTarget };
