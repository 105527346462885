export enum LOLI_FEATURE_FLAG_NAME {
    navigationUpdates = 'wks-navigation-updates',
    editorEngine = 'fun-editor-engine',
    allWorkspacesPage = 'wks-all-workspaces-page',
    metricsPage = 'con-metrics-page',
    rolesAndPermissions = 'wks-roles-and-permissions',
    customVideos = 'fun-custom-videos',
    emailAutomation = 'con-email-automation',
    paymentBlock = 'fun-payment-block',
    customHtmlEmbedBlockNativeEmbed = 'fun-custom-html-embed-block-native-embed',
    funnelEmbedAlpha = 'fun-funnel-embed-alpha',
}

export const OVERWRITES_STORAGE_KEY_PREFIX = 'loli_feature_flag_overwrite__';

export const LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY =
    'loli_feature_flags_last_intercom_event_values';
